import type { ProductConfig } from 'src/config/product';

import { CATEGORIES } from 'src/constants/typologies';
import { countryCode, domainName, language } from './constants';

import { isChristmasTime } from 'src/utils/client/christmas';
import { colors } from 'src/utils/colors';
import { isDev, isProd } from 'src/utils/environment';
import { getOrigin } from '../utils';

import { agenciesWithBaseHeaderColor } from './agenciesWithBaseHeaderColor';
import appIcon from './assets/app_icon.svg';
import appleTouchIcon from './assets/apple-touch-icon.png';
import favicon from './assets/favicon.ico';
import favicon16 from './assets/favicon-16x16.png';
import favicon32 from './assets/favicon-32x32.png';
import logoChristmasAlt from './assets/logo-indomio-alt-natale.svg';
import logoNegative from './assets/logo-indomio-negative.svg';
import logoPositive from './assets/logo-indomio-positive.svg';
import markNegative from './assets/mark-indomio-negative.svg';
import markPositive from './assets/mark-indomio-positive.svg';
import safariPinnedTab from './assets/safari-pinned-tab.svg';
import { mapConfig } from './map';
import {
  alternateDiscoverUrls,
  alternateHeaderUrls,
  discoverUrls,
  headerUrls,
  urls,
} from './urls';

export const config: ProductConfig = {
  ...urls,
  defaultSearchPrefix: '/venta-casas',
  name: 'indomio-es',
  domainName,
  brandName: 'Indomio',
  metricsDomain: 'indomio_es',
  origin: getOrigin(domainName),
  countryCode,
  country: 'spain',
  agencySignUp: '/agenzia/registra-nuova-agenzia.php',
  defaultLocale: language,
  blog: 'https://www.immobiliare.it/news/',
  allowedLanguages: {
    it: {
      code: 'it_IT',
      label: 'Italiano',
    },
    en: {
      code: 'en',
      label: 'English',
    },
    fr: {
      code: 'fr',
      label: 'Français',
    },
    de: {
      code: 'de',
      label: 'Deutsch',
    },
    es: {
      code: 'es_ES',
      label: 'Español',
    },
    pt: {
      code: 'pt',
      label: 'Português',
    },
    ru: {
      code: 'ru',
      label: 'Русский',
    },
    el: {
      code: 'el',
      label: 'Ελληνικά',
    },
  },
  phoneCountryCode: '34',
  logoConfig: {
    url: isChristmasTime() ? logoChristmasAlt : logoNegative,
    positiveUrl: logoPositive,
    width: 234,
    height: 60,
    mobile: {
      altURL: markNegative,
      positiveURL: markPositive,
      width: 26,
      height: 30,
    },
  },
  logoConfigPrintPage: {
    url: logoPositive,
    width: 146,
    height: 29,
  },

  headlineSvg: false,
  assets: {
    appleTouchIcon: appleTouchIcon.src,
    maskIcon: safariPinnedTab,
    shortcutIcon: favicon.src,
    themecolor: colors.brand['01']['500'],
    favicons: [
      {
        url: favicon32.src,
        width: 32,
        height: 32,
        type: 'image/png',
        rel: 'icon',
      },
      {
        url: favicon16.src,
        width: 16,
        height: 16,
        type: 'image/png',
        rel: 'icon',
      },
    ],
  },
  lead: {
    emailRequired: true,
    phoneRequired: false,
  },
  mapConfig,
  cityGuide: {
    mapConfig: {
      ...mapConfig,
      minZoom: 6,
    },
    geographyInfoMapZoom: {
      country: 6,
      region: 8,
      province: 10,
      city: 12,
      cityZone: 12,
      road: 16,
    },
    apiMapZoom: {
      country: 6,
      region: 8,
      province: 10,
      city: 11,
    },
  },
  geographySearch: {
    drawEnabled: true,
    metroEnabled: true,
  },
  geocodeIsolinesApiKey: 'indomio',
  geocodeReverseApiKey: 'indomio_es',
  geocodeAddressApiKey: 'indomio_es',
  geocodeAddressApiOrigin: 'https://www.indomio.es',
  mapResultsLimit: 500000,
  mapResultMaxLabel: 2000,
  mapMaxResultsPerPage: 25,
  listResultsLimit: 500000,
  listResultMaxLabel: 2000,
  listMaxResultsPerPage: 25,
  energyRange: {
    high: ['A'],
    medium: ['B', 'C', 'D'],
    low: ['E', 'F', 'G'],
  },
  user: {
    publishedAdsUrl: '/utente/annunci/pubblicati/',
    savedAdsUrl: '/user/saved/',
    blacklistedAdsUrl: '/utente/annunci/nascosti/',
    lastAdsUrl: '/utente/annunci/recenti/',
    savedSearchesUrl: '/user/searches/',
    messagesUrl: '/utente/messaggi/',
    logoutUrl: '/api-next/user/logout/',
    loginUrl: '/api-next/user/login/',
    registerUrl: '/api-next/user/register/',
    passwordRecoveryUrl: '/api-next/user/password-retrieve/',
    profileUrl: '/utente/profilo/',
    editUrl: (realEstateId) => `/utente/annuncio/${realEstateId}`,
    upgradeUrl: (realEstateId) =>
      `/utente/annuncio/${realEstateId}/visibilita/`,
  },
  socials: {
    facebookUrl: 'https://www.facebook.com/indomioespana',
    instagramUrl: 'https://www.instagram.com/indomio_espana/',
    linkedinUrl: 'https://www.linkedin.com/company/indomio-es/',
  },
  terms: {
    conditionUrl: '/terms/',
    privacyUrl: '/terms/privacy/',
    cookiesUrl: '/terms/cookie/',
  },
  hasFooterLinks: false,
  realEstatePath: '/anuncios/',
  facebookAppId: isDev ? 450294035457393 : 424731191372110,
  gtm: {
    id: 'GTM-TBZ3M2X',
  },
  mapchartImgServiceVersion: '2024071701', // remember to increase polygonsServiceVersion value
  vhostUrlCdnMaps: 'https://s1.indomio.es/c/',
  staticMapUrl: 'https://maps.im-cdn.it/static',
  appStores: {
    iOS: 'https://apps.apple.com/it/app/immobiliare-it-cerchi-casa/id335948517',
    android:
      'https://play.google.com/store/apps/details?id=it.immobiliare.android',
    huawei: 'https://appgallery.huawei.com/#/app/C101715421',
  },
  appConfig: {
    img: appIcon,
    iOS: {
      app_name: 'immobiliare-app',
    },
    android: {
      app_host: 'www.immobiliare.it/home',
      uri_scheme: 'https',
      app_package: 'it.immobiliare.android',
    },
    huawei: {
      app_package: 'it.immobiliare.android',
    },
  },
  preconnectUrls: [
    'https://pic.indomio.es',
    'https://www.googletagmanager.com',
  ],
  brazeConfig: {
    apiKey: isProd
      ? '261c7d8d-580d-4776-8892-c3d3fa6339ba'
      : '77cfd31-9a0d-4d7f-927e-af4c03eae705',
    baseUrl: 'sdk.fra-01.braze.eu',
  },
  headerUrls,
  alternateHeaderUrls,
  discoverUrls,
  alternateDiscoverUrls,
  visibilities: {
    supervetrina: {
      label: 'es_lbl_supervetrina', // i18n-extract-keys ["es_lbl_supervetrina"]
      style: 'supervetrina',
      isMosaic: true,
    },
    vetrina: {
      label: 'es_lbl_vetrina', // i18n-extract-keys ["es_lbl_vetrina"]
      style: 'vetrina',
    },
    star: {
      label: 'es_lbl_star', // i18n-extract-keys ["es_lbl_star"]
      style: 'star',
    },
    top: {
      label: 'es_lbl_top', // i18n-extract-keys ["es_lbl_top"]
      style: 'top',
    },
    premium: {
      label: 'es_lbl_premium', // i18n-extract-keys ["es_lbl_premium"]
      style: 'premium',
    },
  },
  agenciesExpertResultsLimit: 25,
  provincePathSuffix: 'provincia',
  homepageHeadline: {
    default: {
      transKey: 'header_default_payoff_generic', // i18n-extract-keys ["header_default_payoff_generic"]
    },
  },
  homepageExandibleCategories: [
    CATEGORIES.RESIDENZIALE,
    CATEGORIES.NUOVE_COSTRUZIONI,
  ],
  didomiNoticeID: isProd ? 'm3Jcf7zp' : 'pi4YJzMp',
  oAuth: {
    google: {
      clientId:
        '709292487658-9avrk7n4qs7dg4451mlvr8bqc1j1lqce.apps.googleusercontent.com',
    },
  },
  timeZone: 'Europe/Madrid',
  agenciesWithBaseHeaderColor,
  segmentConfig: isProd
    ? {
        apiHost: 'catcher.indomio.es/v1',
        apiKey: 'm9qVpf3ZrGqTGvMO29nSD1BSDhJLt8D0',
      }
    : {
        apiHost: 'catcher-staging.indomio.es/v1',
        apiKey: 'lutEgq8sXhPaNs9xSZMuuwL8iT0ojVMW',
      },
  trackingReferrerRoutes: {
    publishAds: '/utente/annuncio/nuovo',
    userSavedListings: '/user/saved/',
    userSavedSearch: '/user/searches/',
    userMessages: '/utente/messaggi/',
    userHiddenAds: '/utente/annunci/nascosti/',
    userLastAds: '/utente/annunci/recenti/',
    userLastSearch: '/utente/ricerche/recenti/',
    cityGuide: '/mercado-inmobiliario/',
    searchList: '/search-list/',
    detail: '/anuncios/',
    rent: '/alquiler-',
    sell: '/venta-',
    agency: '/agencias-inmobiliarias/([a-z]+)',
    agencyDetail: '/agencias-inmobiliarias/([0-9]+)',
  },
};
